import { twMerge } from "tailwind-merge";
import VideoCard from "../Cards/VideoCard";
import Text from "./Text";

interface SideBySideTextProps {
    paragraphs?: string[];
    textColor?: string;
    backgroundColor?: string;
    imageTextColor?: string;
    title?: string;
    hasText?: boolean;
    titleAlignment?: "left" | "right";
    elementRight?: JSX.Element;
    elementLeft?: JSX.Element;
    imageTitle?: string;
    imageText?: string;
    hasSubTitle?: boolean;
    subTitle?: string;
    paddings?: string;
    reverse?: boolean;
    gaps?: string;
}

export default function SideBySideText({
    paragraphs = [
        "Die einfache Frage, warum es einigen Menschen gut geht und anderen nicht, beschäftigt mich seit meiner Kindheit. Mein fotografisches Gedächtnis, die starke und sehr schnelle Wahrnehmung und meine Art, alles zu hinterfragen, haben mir bei meiner Arbeit immer schon sehr geholfen.",
        "Anders gesagt, ich musste genau verstehen, wieso dass seelische Verletzungen bei einigen  entstehen und bei anderen nicht. Aber auch, was ich tun muss, damit sie wieder repariert werden können.",
    ],
    textColor = "text-black",
    backgroundColor = "bg-yellow",
    title = " Die richtige Frage war entscheidend",
    imageTextColor = "text-yellow",
    hasText = true,
    titleAlignment = "right",
    imageTitle = "neue wege",
    imageText = " die beantwortung der forschungsfrage",
    hasSubTitle = false,
    subTitle = "Auch wenn du weit weg bist",
    paddings = "py-25 px-3 md:px-0 lg:px-32 ",
    reverse = false,
    gaps = "lg:gap-12 md:gap-2 lg:space-y-0 space-y-4",

    elementLeft = (
        <VideoCard
            title={imageTitle}
            playPlacement="top-14 left-36 lg:top-44 lg:left-72"
            textColor={imageTextColor}
            text={imageText}
        />
    ),
    elementRight = (
        <Text
            paragraphs={paragraphs}
            hasText={hasText}
            title={title}
            titleSize="text-xl3 lg:text-5xl"
            paddings="px-3 lg:w-1/2"
            textColor={textColor}
            hasSubTitle={hasSubTitle}
            subTitle={subTitle}
        />
    ),
}: SideBySideTextProps) {
    return (
        <div>
            <div
                className={twMerge(
                    "w-full justify-start items-center ",
                    reverse
                        ? "flex flex-col-reverse md:flex lg:flex-row"
                        : " lg:flex md:flex",
                    gaps,
                    paddings,
                    backgroundColor,
                )}
            >
                {elementLeft}
                {elementRight}
            </div>
        </div>
    );
}
