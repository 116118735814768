"use client";
import { WatchVideoButton } from "@/stories/Button.stories";
import type { FAQ } from "@/types";
import { Accordion } from "@mantine/core";
import { useEffect, useState } from "react";
import Button from "./Cards/Button";
import InfoModal from "./Modal";
import PlayerInPage from "./Player/PlayerInPage";
import { sendGTMEvent } from "./Analytics";
interface FAQProps {
    FAQItem: FAQ[];
    button?: JSX.Element;
    answerIsList?: boolean;
    isHomePage?: boolean;
}

export default function FAQAccordion({
    FAQItem,
    button,
    answerIsList,
    isHomePage,
}: FAQProps) {
    const [id, setId] = useState("");
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string | null>(null);

    useEffect(() => {
        if (value) {
            const event = {
                event: "faq",
                faq_question: value,
                faq_action: "expanded",
            };
            sendGTMEvent(event);
            console.log("Sent GTM event", event);
        } else {
            const event = {
                event: "faq",
                faq_question: value,
                faq_action: "collapsed",
            };
            sendGTMEvent(event);
            console.log("Sent GTM event", event);
        }
    });
    return (
        <div className={isHomePage ? "" : "w-120"}>
            <Accordion variant="separated" defaultValue={FAQItem[0]?.question}>
                {FAQItem.map((item) => {
                    return (
                        <Accordion.Item
                            key={item.question}
                            value={item.question}
                            className={`${item.borderColor} `}
                        >
                            <Accordion.Control
                                className="font-extrabold text-lg"
                                onClick={() => {
                                    sendGTMEvent({
                                        event: "faq",
                                        faq_question: item.question,
                                        faq_action: "",
                                    });
                                }}
                            >
                                <p className="font-bold">{item.question}</p>
                            </Accordion.Control>
                            <Accordion.Panel>
                                <div className="flex flex-col">
                                    {item.answer.map((item) => (
                                        <div key={item}>
                                            {item}
                                            <br />
                                        </div>
                                    ))}
                                    {item.hasVideo && (
                                        <>
                                            <Button
                                                backgroundColor={
                                                    WatchVideoButton.args
                                                        ?.backgroundColor
                                                }
                                                border={WatchVideoButton.args?.border}
                                                hasIcon={WatchVideoButton.args?.hasIcon}
                                                iconPlacement={
                                                    WatchVideoButton.args?.iconPlacement
                                                }
                                                iconSrc={WatchVideoButton.args?.iconSrc}
                                                paddings={
                                                    WatchVideoButton.args?.paddings
                                                }
                                                rounded={WatchVideoButton.args?.rounded}
                                                size={WatchVideoButton.args?.size}
                                                text={WatchVideoButton.args?.text}
                                                textColor={
                                                    WatchVideoButton.args?.textColor
                                                }
                                                textSize={
                                                    WatchVideoButton.args?.textSize
                                                }
                                                onClick={() => {
                                                    setId(item.video);
                                                    setOpen(true);
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                            </Accordion.Panel>
                        </Accordion.Item>
                    );
                })}
            </Accordion>

            <InfoModal onClose={() => setOpen(false)} opened={open} size="full">
                <PlayerInPage videoId={id} />
            </InfoModal>
        </div>
    );
}
