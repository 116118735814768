import Image from "next/image";
import { twMerge } from "tailwind-merge";

interface VideoCardProps {
    title?: string;
    text?: string;
    textColor?: string;
    imageSize?: string;
    textPlacement?: string;
    img?: string;
    playPlacement?: string;
    hasPlayButton?: boolean;
}

export default function VideoCard({
    title,
    text,
    textColor,
    textPlacement = "top-32.5 lg:top-58",
    imageSize = "w-77 h-55 lg:w-150 lg:h-86",
    img = "/images/RicoPlaceholder.png",
    playPlacement = "top-40 left-71",
    hasPlayButton = true,
}: VideoCardProps) {
    return (
        <div className="z-10 rounded-lg flex justify-center">
            <div className={twMerge("relative", imageSize)}>
                <div
                    className={twMerge(
                        "left-0 top-0 absolute justify-center items-center inline-flex",
                        imageSize,
                    )}
                >
                    <Image
                        alt=""
                        height={415}
                        width={560}
                        style={{ objectFit: "cover" }}
                        className={twMerge(
                            "bg-gradient-to-b from-black to-black",
                            imageSize,
                        )}
                        src={img}
                    />
                </div>
                {hasPlayButton && (
                    <div
                        className={twMerge(
                            "p-3 p-auto  absolute bg-black bg-opacity-40 rounded-8xl justify-start items-center gap-1.5 inline-flex",
                            playPlacement,
                        )}
                    >
                        <Image
                            alt=""
                            height={20}
                            width={20}
                            className="w-9 h-9 relative"
                            src="/Icons/playIconWhite.svg"
                        />
                    </div>
                )}
                <div
                    className={twMerge(
                        "text-center absolute flex-col justify-start items-start gap-1 inline-flex",
                        textColor,
                        textPlacement,
                    )}
                >
                    <div className="self-stretch text-center  text-base lg:text-2xl font-normal  uppercase lg:leading-10">
                        {title}
                    </div>
                    <div className="self-stretch text-center text-2xl lg:text-xl4 font-bold font-['Avenir Next Condensed'] uppercase leading-6 lg:leading-10">
                        {text}
                    </div>
                </div>
            </div>
        </div>
    );
}
